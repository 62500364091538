*{
    padding: 0%;
    margin: 0;
    box-sizing: border-box;
    font-family: "Rubik",sans-serif;
}

/*--------------------------------------------------*/
/*navbar section start*/

.topbar {
    color: #fff;
    background-color: #8f1732;
}
.topbar ul {
    overflow: hidden;
    font-size: 18px;
}
.topbar ul li{
    list-style: none;
    float: left;
    padding-top: 5px;
    margin-right: 16px;
}
.topbar .contact-info a {
    color: #fff;
    text-decoration: none;
}
.topbar .social ul li svg{
    color: #fff;
}
.topbar ul li i{
    display: inline-block;
    padding-right: 5px;
    position: relative;
    top: 2px;
}
.top_nav {
    background-color: #fff;
}
.top_nav .navbar-nav a, .top_nav .navbar-nav a:hover {
    font-size: 18px;
    margin-right: 1vw;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}
.top_nav .navbar-nav .active {
    font-size: 18px;
    margin-right: 1vw;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #8f1732;
}

/*--------------------------------------------------*/
/*navbar section end*/


/*--------------------------------------------------*/
/*slider section start*/
.carousel-caption {
    bottom: 0.25rem;
    color: #fff;
    left: 15%;
    /* padding-bottom: 1.25rem; */
    /* padding-top: 1.25rem; */
    position: absolute;
    right: 15%;
    text-align: center;
}
.carousel .carousel-caption h5{
    color: #fff;
    font-size: 50px;
}
.carousel .carousel-caption p{
    font-size: 18px;
    color: #fff;
}
@media screen and (max-width: 676px) {
    .carousel-caption {
    color: #fff;
    left: 15%;
    position: absolute;
    right: 15%;
    text-align: center;
    top: 0;
}
  .carousel .carousel-caption h5{
    color: #fff;
    font-size: 30px;
  }
}
/*--------------------------------------------------*/
/*slider section end*/


/*--------------------------------------------------*/
/*top service section start*/
.top_service_quote{
    background-color: #1877f2;
    padding: 20px 0;
    color: #ffffff;
    text-align: center;
}
.top_service_quote .builder h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 0;
}
.top_service_quote .builder svg {
    font-size: 40px;
    color: #fff;
    align-items: center;
}

/*--------------------------------------------------*/
/*top service section end*/


/*--------------------------------------------------*/
/*about section start*/

::selection {
    color: #fff;
    background-color: #f0542c;
}
.md-section {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 9;
    padding-top: 50px;
    padding-bottom: 50px;
}
.md-section .sec-title {
    margin-bottom: 30px;
}

.md-section .sec-title__title span {
    color: #f0542c;
}
.md-section .sec-title__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.sec-title__divider {
    display: block;
    width: 40px;
    height: 2px;
    background-color: #f0542c;
}
.md-section p {
    margin-top: 0;
    margin-bottom: 10px;
}
.md-section .btn-primary {
    background-color: #f0542c;
}
.md-section .btn-outline {
    border: 1px solid #fff;
}

.md-section .btn {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;
    display: inline-block;
    border: none;
    transition: all .3s ease !important;
}
.md-section .about {
    display: block;
    position: relative;
    margin-bottom: 50px;
    margin-right: 30px;
    min-height: 400px;
}

.md-section .about img {
    height: 400px;
    width: 425px;
    border: 0;
}

.md-section .quote-02 * {
    color: #fff;
}
.md-section .authorbox {
    position: relative;
    padding: 30px 8px;
    overflow: hidden;
}

.md-section .authorbox__avartar {
    position: relative;
    float: right;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 20px;
}
.md-section .authorbox__avartar img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.md-section .authorbox__info {
    text-align: right;
}
.md-section .authorbox__name {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 10px;
}
@media (min-width: 993px) {
    .md-section .about .about-quote {
        position: relative;
        opacity: .85;
        margin-top: 15px;
    }
}
@media (max-width: 993px) {
    .md-section .about img {
        display: none;
    }
}
@media (min-width: 993px) and (min-width: 481px) {
    .md-section .about .about-quote {
        position: absolute;
        right: -30px;
        bottom: -30px;
        float: none;
    }
}

.md-section .quote-02 {
    max-width: 300px;
    background-color: #f0542c;
    border: 5px solid #fff;
    padding: 20px;
    margin: 0;
    box-shadow: 0 0 5px rgba(68, 68, 68, .6);
}
/*--------------------------------------------------*/
/*about section end*/


/*--------------------------------------------------*/
/*services section start*/

.service-section {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 9;
    padding-top: 50px;
    padding-bottom: 50px;
}
.service-section .sec-title {
    margin-bottom: 30px;
}

.service-section .md-text-center, .text-center {
    text-align: center !important;
}
.service-section .sec-title__lg-title .sec-title__title {
    font-size: 25px;
}
.service-section .sec-title__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.service-section h2{
    font-weight: bold;
    line-height: 1.4;
    color: #444;
}
.service-section .md-text-center .sec-title__divider {
    margin-left: auto;
    margin-right: auto;
}
.service-section .sec-title__divider {
    display: block;
    width: 40px;
    height: 2px;
    background-color: #f0542c;
}
.service-section .services {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 15px;
}
.service-section .services .services__img {
    margin-bottom: 15px;
}
.service-section .services img {
    width: 100%;
    height: auto;
    border: 0;
}
.service-section .services .services__title {
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0 7px;
}
 .service-section .services .services__title a {
    color: inherit;
    text-decoration: none;
}
.service-section .services .services__desc {
    margin-bottom: 30px;
}
.service-section .btn-primary {
    background-color: #f0542c;
}

.service-section .btn {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;
    display: inline-block;
    border: none;
    transition: all .3s ease !important;
}
.md-section .service-detail img{
    width: 100%;
}
/*--------------------------------------------------*/
/*service section end*/


/*--------------------------------------------------*/
/*projects section start*/

.project_section {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 9;
    padding-top: 50px;
    padding-bottom: 50px;
}
.project_section .sec-title {
    margin-bottom: 30px;
}

.project_section .md-text-center, .text-center {
    text-align: center !important;
}
.project_section .sec-title__lg-title .sec-title__title {
    font-size: 25px;
}
.project_section .sec-title__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.project_section .md-text-center .sec-title__divider {
    margin-left: auto;
    margin-right: auto;
}
.project_section .sec-title__divider {
    display: block;
    width: 40px;
    height: 2px;
    background-color: #f0542c;
}

.project_section .caption {
    position: relative;
    bottom: 206px;
    background-color: #fff;
    opacity: 0.6;
}
.project_section h2{
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.2;
    color: #444;
    margin-left: 5px;
}
 .project_section a {
    color: inherit;
    text-decoration: none;
}
/*--------------------------------------------------*/
/*project section end*/


/*--------------------------------------------------*/
/*home achievement section start*/
.home_achieve_quote{
    background-color: #8f1732;
    padding: 20px 0;
    color: #ffffff;
    text-align: center;
}

.home_achieve_quote .achieve .counter {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    display: block;
    padding-top: 23px;
}
.home_achieve_quote .achieve h6 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 0;
}
.home_achieve_quote .achieve svg {
    font-size: 40px;
    color: #fff;
    align-items: center;
}
/*--------------------------------------------------*/
/*home achievement section end*/
.md-section .contact{
    font-size: 18px;
}
.md-section .contact li{ 
    list-style: none;
}
.md-section .contact li span{ 
    font-size: 18px;
    font-weight: 600;
}
.md-section .contact li a{ 
    text-decoration: none;
    color: #212529;
}

.md-section .socail_icon {
    font-size: 32px;
    display: flex;
}
.md-section .socail_icon li{
    list-style: none;
    color: #fff;
    margin-right: 5px;
}


/*--------------------------------------------------*/
/*breadcrumb section start*/
.top_breadcrumb {
    height: 150px;
}
.top_breadcrumb ol li{
    margin-top: 120px;
}
.top_breadcrumb .breadcrumb-item a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}
.top_breadcrumb .active{
    color: #f9ff00;
    text-decoration: none;
    font-weight: 600;
}

/*--------------------------------------------------*/
/*breadcrumb section end*/


/*--------------------------------------------------*/
/*footer section start*/

.footer .footer_body .fcollect {
    margin-top: 50px;
}
.footer .footer_body{
    background-color: #8f1732;
    padding: 20px 0;
    color: #ffffff;
}
.footer .footer_body .quote_title {
    font-size: 20px;
    color: #fff;
    font-style: italic;
    font-weight: normal;
    margin-top: 7px;
    margin-bottom: 0;
}
.footer .footer_body .btn {
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;
    display: inline-block;
    transition: all .3s ease !important;
}

.footer .footer_body h2{
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 10px;
}
.footer .footer_about .socail_icon{
    display: flex;
}
.footer .footer_about .socail_icon li{
    list-style: none;
    font-size: 25px;
    margin-right: 6px;
}
.footer .footer_about .socail_icon svg{
    color: #fff;
}
.footer .footer_about .quick_link li{
    list-style: none;
    font-size: 18px;
    margin-bottom: 10px;
}
.footer .footer_about .quick_link li a {
    text-decoration: none;
    color: #fff;
}
.footer .footer_about .my_address li{
    list-style: none;
    font-size: 18px;
    margin-bottom: 10px;
}
.footer .footer_about .my_address li a {
    text-decoration: none;
    color: #fff;
}
.footer .copyright_area {
    background: #72303f;
    font: 400 14px/30px "Roboto", sans-serif;
    color: #fefefe;
    text-align: center;
}
.footer .copyright_area a {
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    text-decoration: none;
}